import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
// import * as Notifications from "expo-notifications";

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

// async function registerForPushNotificationsAsync() {
//   let token;

//   const { status: existingStatus } = await Notifications.getPermissionsAsync();
//   let finalStatus = existingStatus;

//   if (existingStatus !== 'granted') {
//       const { status } = await Notifications.requestPermissionsAsync();
//       finalStatus = status;
//   }

//   if (finalStatus !== 'granted') {
//       alert('Failed to get push token for push notification!');
//       return;
//   }

//   token = (await Notifications.getExpoPushTokenAsync()).data;

//   return token;
// }

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  /*

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true
    })
  });

  registerForPushNotificationsAsync()
  .then(token => {
    // Notifications.scheduleNotificationAsync({
    //   content: {
    //     title: "Akari",
    //     body: "Velkommen til Akari."
    //   },
    //   trigger: {
    //     seconds: 10
    //   }
    // }).then(id => {
    //   console.log(id);
    // });
  });

  */

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}
