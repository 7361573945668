import React from 'react';
import { StyleSheet, Image, ImageBackground, TouchableOpacity, Dimensions, SafeAreaView } from 'react-native';
import Svg, { Circle, Rect, Path } from 'react-native-svg';
import { Platform } from 'react-native';

export default function BannerSVG() {
    /*
        <Svg
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 824 468"
        xmlSpace="preserve"
        >
        <Path
        fill="#F9D379"
        opacity={1}
        d="M1 467V1.06h824c0 114.299 0 228.658-.358 343.495a146.334 146.334 0 01-10.065 5.604c-33.769 17.176-70.066 23.992-107.49 25.717-44.587 2.055-88.846-2.46-132.94-8.217-52.623-6.87-105.085-14.973-157.718-21.748-24.898-3.205-50-5.7-75.071-6.45-26.584-.793-53.273.24-79.856 1.632-26.707 1.397-52.72 7.317-78.122 15.685-52.073 17.153-98.22 45.115-141.568 78.074C28.03 445.332 14.594 456.272 1 467z"
        />
        <Path
        fill="#FFF"
        opacity={1}
        d="M1 467.5c13.594-11.229 27.029-22.168 40.812-32.648 43.349-32.959 89.495-60.92 141.568-78.074 25.403-8.368 51.415-14.288 78.122-15.685 26.583-1.392 53.272-2.425 79.856-1.631 25.071.748 50.173 3.244 75.07 6.449 52.634 6.775 105.096 14.878 157.718 21.748 44.095 5.757 88.354 10.272 132.941 8.217 37.424-1.725 73.721-8.541 107.49-25.717 3.262-1.659 6.473-3.415 10.065-5.143C825 386.333 825 427.666 825 469c-273.167 0-546.333.002-819.5-.041-1.5 0-3-.626-4.5-1.459z"
        />
        </Svg>
    */


    if (Platform.OS == "web") {
        return (
            <Image
            style={styles.bannerWeb}
            source={{uri: ""}}></Image>
        );
    } else {
        return (
            <Image
            style={styles.banner}
            source={{uri: "https://akari.no/wp-content/uploads/2023/04/banner.png"}}></Image>
        );
    }
}

const styles = StyleSheet.create({
    banner: {
      width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
      height: 215
    },
    bannerWeb: {
        width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
        height: 215
      }
});