import React, { useEffect, useState } from 'react';
import { StyleSheet, SafeAreaView, ScrollView, TouchableOpacity, Appearance, Image, Dimensions } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { decode } from "html-entities";
import Svg, { Circle, Rect, Path } from 'react-native-svg';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import BannerSVG from '../components/BannerSVG';

type PostData = {

};

export default function TabFourScreen(props) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<Array<PostData>>([]);

  useEffect(() => {
    fetch("https://akari.no/wp-json/wp/v2/posts?_embed")
      .then(posts => posts.json())
      .then(posts => {
        setData(posts);
        setLoading(false);
      });
  }, []);

  const colorScheme = Appearance.getColorScheme();

  return (
    <View style={colorScheme == "light" ? styles.container : styles.containerDark}>
      <ScrollView>
        <BannerSVG />

        <View style={{backgroundColor: "rgba(0, 0, 0, 0)", zIndex: 25, height: 32}}>
          <Text style={styles.welcomeText}>hekta på ...</Text>
          <Text style={styles.companyNameText}>Oppslagstavla</Text>
        </View>

        <View style={styles.posts}>
          {data.map(post => (
              <View style={styles.post} key={post.link}>
                <TouchableOpacity onPress={() => {
                  WebBrowser.openBrowserAsync(post.link);
                }}>
                  <Image
                    style={styles.postImage}
                    source={{uri: decode(post._embedded["wp:featuredmedia"][0].source_url)}}></Image>

                  <Text style={styles.postTitle} key={post.title.rendered}>{decode(post.title.rendered)}</Text>
                </TouchableOpacity>
              </View>
            ))}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#fff"
  },

  containerDark: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#000"    
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 25,
    marginHorizontal: 10
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  getStartedText:
  {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },

  companyNameText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 28
  },
  
  welcomeText: {
    textAlign: "center",
    marginTop: -150,
    fontSize: 18
  },
  
  mainText: {
    marginTop: -200
  },

  header: {
    width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
    height: 225,
    position: "absolute",
    top: 0,
    zIndex: 20,
    backgroundColor: "rgba(0, 0, 0, 0)"
  },

  posts: {
    maxWidth: 800,
    marginHorizontal: 10
  },

  post: {
    marginVertical: 25,
    backgroundColor: "#eee"
  },

  postTitle: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 15
  },

  postContent: {
    lineHeight: 25
  },

  postImage: {
    width: "100%",
    height: 150
  }
});
