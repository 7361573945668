import React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Text, View } from '../components/Themed';

export default function WebsiteIcons(props) {
    const websites = props.websites.map(x => x.replace("https://www.", ""))
        .map(x => x.replace("https://", ""))
        .filter(x => x != "")
        .map(x => x.slice(0, -1));

    let isFacebook = false;
    let isGoogle = false;

    return (
        <View>
            {websites.map((x, index) => {
                if (x.includes("facebook.") && !isFacebook) {
                    isFacebook = true;
                    return <Text style={{textAlign: "center", marginBottom: 8, fontWeight: "bold"}} key={x}><Entypo name="facebook" size={24} color="black" /></Text>
                } else if (x.includes("google.") && !isGoogle) {
                    isGoogle = true;
                    return <Text style={{textAlign: "center", marginBottom: 8, fontWeight: "bold"}} key={x}><AntDesign name="google" size={24} color="black" /></Text>
                } else {
                    if (!x.includes("facebook.") && !x.includes("google.") && !x.includes(props.domain)) {
                        return <Text style={{textAlign: "center", marginBottom: 8, fontWeight: "bold"}} key={x}>{x}</Text>
                    }
                }
            })}
        </View>
    );
}
