import React, { useEffect, useState } from "react";
import * as WebBrowser from 'expo-web-browser';
import { StatusBar } from 'expo-status-bar';
import { Platform, StyleSheet, Switch, TouchableOpacity } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import Colors from "../constants/Colors";
import AsyncStorage from "@react-native-async-storage/async-storage";

type ClientInfo = {
  client_name: string,
  main_contact: string,
  income_per_thousand_visits: number,
  content: string  
};

export default function ModalScreen({navigation: {navigate}}) {
  const [isLoading, setLoading] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => setIsEnabled(previousState => !previousState);
  const [dataClient, setDataClient] = useState<ClientInfo>();

  useEffect(() => {
    AsyncStorage.getItem("client_info")
      .then(clientInfo => JSON.parse(clientInfo || "{}"))
      .then(clientInfo => setDataClient(clientInfo))
      .finally(() => setLoading(false));
  }, []);

  return (
    <View style={styles.container}>
      {/* <Text style={styles.title}>Innstillinger</Text> */}
      {/* <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" /> */}
      {/* <EditScreenInfo path="/screens/ModalScreen.tsx" /> */}

      {/* <Text>Enable</Text>
        <Switch
          trackColor={{ false: "#767577", true: "#81b0ff" }}
          thumbColor={isEnabled ? "#f5dd4b" : "#f4f3f4"}
          ios_backgroundColor="#3e3e3e"
          onValueChange={toggleSwitch}
          value={isEnabled}
        /> */}

      {isLoading ? <Text></Text> : <Text>Logget inn som {dataClient!.client_name}.</Text>}

      <TouchableOpacity onPress={(onPressContact)} style={styles.helpLink}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Kontakt oss</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={onPressPrivacyLink} style={styles.helpLink}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Personvern</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => {
        AsyncStorage.removeItem("client_info")
        .then(() => {
          navigate("Login");
        });
      }} style={styles.logoutLink}>
        <Text style={styles.logoutLinkText} lightColor={Colors.light.tint}>Logg ut</Text>
      </TouchableOpacity>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

function onPressContact()
{
    WebBrowser.openBrowserAsync(
        'https://akari.no/ta-kontakt'
    );
}

function onPressPrivacyLink()
{
    WebBrowser.openBrowserAsync(
        'https://akari.no/personvern'
    );
}

function onPressLogout() {
    
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  helpLink: {
    paddingVertical: 15,
  },

  helpLinkText: {
    textAlign: 'center',
  },

  logoutLink: {
    paddingVertical: 15,
  },

  logoutLinkText: {
    textAlign: 'center',
  },  
});
