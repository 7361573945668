import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Svg, { Circle, Rect, Path } from 'react-native-svg';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import BannerSVG from '../components/BannerSVG';

type Employee = {
  name: string,
  number: string,
  email: string,
  role: string,
  image: string 
};

type EmployeeInfo = {
  info: Array<Employee>
};

export default function TabTwoScreen() {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<EmployeeInfo>();

  const [clientInfo, setClientInfo] = useState();

  let employeesList = [];

  // UA-66877170-6

  useEffect(() => {
    AsyncStorage.getItem("client_info")
    .then(clientInfo => JSON.parse(clientInfo))
    .then(clientInfo => {
      setClientInfo(clientInfo);

      fetch(`https://akari.no/wp-json/akari/v1/employeeinfo/${clientInfo.marketing_team.join(",")}`)
        .then(res => res.json())
        .then(json => setData(json))
        .finally(() => setLoading(false));
    });
  }, []);

  if (!isLoading) {
    data!.info.forEach(employee => {
      employeesList.push(
        <View style={styles.grid}>
        <View>
          <View>
            <Image source={{uri: employee.image}}
              style={{width: 100, height: 100, borderRadius: 200, marginRight: 25}} />
          </View>
        </View>
      
        <View>
          <View>
          <Text style={styles.getStartedText}>{employee.role}</Text>
          <Text style={styles.advisorName}>{employee.email}</Text>
          </View>
          <View style={styles.grid}>
          <View>
          <TouchableOpacity onPress={() => onPressTelLink(employee.number)} style={[styles.btnContact, {marginRight: 10}]}>
    <Text style={styles.advisorLink} lightColor={Colors.light.tint}>Ring meg</Text>
  </TouchableOpacity>
          </View>
          <View>
          <TouchableOpacity onPress={() => onPressEmailLink(employee.email)} style={styles.btnContact}>
    <Text style={styles.advisorLink} lightColor={Colors.light.tint}>Send mail</Text>
  </TouchableOpacity>
          </View>              
        </View>              
        </View>
    </View>
      );
    });
  }

    return (
    <View style={styles.container}>
      <BannerSVG />

      <View style={{backgroundColor: "rgba(0, 0, 0, 0)", height: 32}}>
        <Text style={styles.welcomeText}>Din kontakt i</Text>
        <Text style={styles.companyNameText}>Akari</Text>
      </View>

      {/* <Text style={styles.title}>Snakk med en rådgiver</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" /> */}

      {/* <Text style={styles.mainText}>Din hovedkontakt er {isLoading ?
      <Text></Text> : data!.info.name}.</Text> */}
      
      <View style={styles.adviserWrapper}>
        {employeesList}
      </View>

      {/* <TouchableOpacity onPress={onPressContactLink} style={styles.btnContact}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Kontakt oss</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={onPressSupportLink} style={styles.btnContact}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Support</Text>
      </TouchableOpacity> */}

      {/* <Text
          style={styles.getStartedText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)"></Text>

      <TouchableOpacity onPress={onPressContactLink} style={styles.helpLink}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Kontakt oss</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={onPressSupportLink} style={styles.helpLink}>
        <Text style={styles.helpLinkText} lightColor={Colors.light.tint}>Support</Text>
      </TouchableOpacity>       */}
    </View>
  );
}

function onPressTelLink(phoneNumber: string) {
  Linking.openURL(`tel:${phoneNumber}`);
}

function onPressEmailLink(mail: string) {
  Linking.openURL(`mailto:${mail}`)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  adviserWrapper: {
    
  },
  advisorName: {
    fontSize: 20,
    fontWeight: "bold"
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  btnContact: {
    
  },
  companyNameText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 28
  },
  welcomeText: {
    textAlign: "center",
    marginTop: -150,
    fontSize: 18
  },
  mainText: {
    marginTop: 100
  },
  header: {
    width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
    height: 225,
    position: "absolute",
    top: 0
  },  
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  getStartedText:
  {
      fontSize: 17,
      lineHeight: 24
  },
  helpLink: {
    paddingVertical: 15,
  },

  helpLinkText: {
    textAlign: 'center',
  }, 

  advisorLink: {
    color: "#212121",
    backgroundColor: "#f9d379",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 15,
    marginVertical: 25
  },

  tinyLogo: {
    width: 50,
    height: 50,
  },

  grid: {
    flex: 1,
    flexDirection: "row"
  }
});
