import React from 'react';
import { Dimensions, Image, Button, Pressable, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import BannerSVG from '../components/BannerSVG';

export default function LoginScreen({ navigation }: RootStackScreenProps<'Login'>) {
  const [username, onChangeUsername] = React.useState("");
  const [password, onChangePassword] = React.useState("");

  AsyncStorage.getItem("client_info")
  .then(clientInfo => JSON.parse(clientInfo))
  .then(clientInfo => {
    if (clientInfo != null && clientInfo.id != null) {
      navigation.replace("Root");
    }
  });

  function login() {
    fetch(`https://akari.no/wp-json/akari/v1/auth?user=${username}&pass=${password}`)
      .then(res => res.json())
      .then(json => {
        if (json.token != null) {
          const token = json.token;

          fetch(`https://akari.no/wp-json/akari/v1/clientinfo/${token}`)
            .then(res => res.json())
            .then(json => {
              if (json.info != null) {
                json.info.id = token;
        
                AsyncStorage.setItem("client_info", JSON.stringify(json.info))
                  .then(() => {
                    AsyncStorage.getItem("client_info")
                      .then(clientInfo => {
                        navigation.replace("Root");
                      });
                  });
              }
            });
        }
      })
  }

  return (
    <View style={styles.container}>
      <BannerSVG />

      <Text style={styles.title}>Velkommen tilbake!</Text>

      <TextInput
        style={styles.username}
        onChangeText={(onChangeUsername)}
        value={username}
        placeholder="Brukernavn"
      />

      <TextInput
        style={styles.input}
        secureTextEntry={true}
        onChangeText={(onChangePassword)}
        value={password}
        placeholder="Innloggingskode"
      />

      <Pressable style={styles.button} onPress={login}>
          <Text style={styles.buttonText}>Logg inn</Text>
      </Pressable>

      <Text>Har du ingen innloggingskode?</Text>
      <Text>Ta kontakt med oss, så sender vi over.</Text>
      
      <Image style={styles.tinyLogo} source={require('../assets/images/akari-logo-yellow-header.png')} />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#fcc64a",
    paddingVertical: 15,
    paddingHorizontal: 75,
    borderRadius: 25,
    marginVertical: 25
  },

  buttonText: {

  },

  container: {
    flex: 1,
    alignItems: 'center',
    padding: 0,
    margin: 0,
    height: Dimensions.get("window").height
  },
  
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 25
  },
  
  link: {
    marginTop: 15,
    paddingVertical: 15
  },
  
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },

  input: {
    height: 40,
    maxWidth: 400,
    width: Dimensions.get("window").width - 50,
    margin: 12,
    borderBottomWidth: 1,
    borderColor: "#eee",
    padding: 10
  },

  username: {
    maxWidth: 400,
    width: Dimensions.get("window").width - 50,
    padding: 10,
    borderBottomWidth: 1,
    borderColor: "#eee"
  },

  tinyLogo: {
    height: 64,
    width: 200,
    position: "absolute",
    bottom: 25
  },

  banner: {
    width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
    height: 225
  }
});
