import { StyleSheet, Image, ImageBackground, TouchableOpacity, Dimensions, SafeAreaView } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Svg, { Circle, Rect, Path } from 'react-native-svg';
import BannerSVG from '../components/BannerSVG';
import { Platform } from 'react-native';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import WebsiteIcons from '../components/WebsiteIcons';
import { WebView } from 'react-native-webview';

type AnalyticsData = {
  date: string,
  total_sessions: number
};

type ClientInfo = {
  client_name: string,
  main_contact: string,
  domain: string,
  income_per_thousand_visits: number,
  income: string,
  clickup_iframe: string,
  google_looker_iframe: string,
  content: string  
};

export default function TabOneScreen({navigation: {navigate}}: RootTabScreenProps<'TabOne'>)
{
  const [isLoading, setLoading] = useState(true);
  const [isLoadingAnalytics, setLoadingAnalytics] = useState(true);

  const [data, setData] = useState<Array<AnalyticsData>>([]);
  const [dataClient, setDataClient] = useState<ClientInfo>();

  // UA-66877170-6

  useEffect(() => {
    AsyncStorage.getItem("client_info")
      .then(clientInfo => JSON.parse(clientInfo || "{}"))
      .then(clientInfo => {
        setDataClient(clientInfo)        
        setLoading(false);

        fetch(`https://akari.no/wp-json/akari/v1/analytics/${clientInfo.id}`)
          .then(res => res.json())
          .then(json => setData(json))
          .finally(() => {
            setLoadingAnalytics(false);
          });
      });
  }, []);

  // const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
  const totalVisitors = data.map(x => parseInt(x.total_users)).reduce((a, b) => a + b, 0);

  if (Platform.OS == "web") {
    return (
      <View style={styles.container}>
        <View style={{flex: 1, flexDirection: "row", flexGrow: 1, width: "100%"}}>
          <View style={{position: "absolute", height: 50, width: Dimensions.get("window").width * 0.5, zIndex: 999}}>
            <Image style={{width: 150, height: 50}} source={require('../assets/images/akari-logo-yellow-header.png')} />
          </View>

          <View>
            {isLoading ? <Text></Text> : 
                        <iframe src={dataClient!.clickup_iframe} height={'100%'} width={Dimensions.get("window").width * 0.5} />}
          </View>
          <View>
            {isLoading ? <Text></Text> : 
                        <iframe src={dataClient!.google_looker_iframe} height={'100%'} width={Dimensions.get("window").width * 0.5}  />}
          </View>
        </View>

        <TouchableOpacity onPress={() => {
          AsyncStorage.removeItem("client_info")
          .then(() => {
            navigate("Login");
          });
        }} style={styles.logoutLink}>
          <Text style={styles.logoutLinkText} lightColor={Colors.light.tint}>Logg ut</Text>
        </TouchableOpacity>
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <BannerSVG />
  
        <View style={{backgroundColor: "rgba(0, 0, 0, 0)", zIndex: 25, height: 32}}>
          <Text style={styles.welcomeText}>Velkommen tilbake</Text>
          <Text style={styles.companyNameText}>{isLoading ? <Text></Text> : dataClient!.client_name}</Text>
        </View>
  
        <ScrollView style={{width: Dimensions.get("window").width}} snapToInterval={Dimensions.get("window").width} decelerationRate="fast" horizontal>
          <View style={{width: Dimensions.get("window").width}}>
            {/* <Text style={{textAlign: "center", fontWeight: "bold"}}>Siden oppstart har</Text> */}
  
            {isLoading ? <Text style={styles.textStat}>...</Text> :
            <View>
              <Text style={{textAlign: "center", fontWeight: "bold"}}>I år har</Text>
              <Text style={{textAlign: "center", fontWeight: "bold"}}>nettsiden din generert</Text>
  
              <Text style={styles.textStat}>{Math.round((parseInt(dataClient!.income) / 12) / totalVisitors)} kr</Text>
              <Text style={{textAlign: "center"}}>Pr. bruker</Text>
            </View>      
            }
  
            {/* <Text style={{textAlign: "center"}}>i året</Text> */}
  
            {/* {isLoadingAnalytics ? <Text style={styles.textStat}>...</Text> : <Text style={styles.textStat}>{(data.map(x => parseInt(x.total_users))
              .reduce((a, b) => a + b, 0) * dataClient.income_per_thousand_visits).toLocaleString()}</Text>}
            <Text style={{textAlign: "center"}}>i måneden</Text>
            {isLoadingAnalytics ? <Text style={styles.textStat}>...</Text> : <Text style={styles.textStat}>{(data.map(x => parseInt(x.total_users))
              .reduce((a, b) => a + b, 0) * dataClient.income_per_thousand_visits).toLocaleString()}</Text>}
              <Text style={{textAlign: "center"}}>i uken</Text> */}
            </View>
  
            <View style={{width: Dimensions.get("window").width}}>
              <Text style={{textAlign: "center", fontWeight: "bold"}}>Dine mest</Text>
              <Text style={{textAlign: "center", fontWeight: "bold", marginBottom: 25}}>populære undersider</Text>
  
              {isLoadingAnalytics ? <Text>...</Text> : data[0].total_page.slice(0, 10).map(x => {if (x == "/") {return "/(hjemmeside)/";} else {return x;}}).map(x => <Text style={{textAlign: "center", marginBottom: 8, fontWeight: "bold"}} key={x}>{x.slice(1, -1)}</Text>)}
            </View>
  
            <View style={{width: Dimensions.get("window").width}}>
              <Text style={{textAlign: "center", fontWeight: "bold"}}>Hvor kommer</Text>
              <Text style={{textAlign: "center", fontWeight: "bold", marginBottom: 25}}>besøkende fra?</Text>
  
              {isLoadingAnalytics ? <Text>...</Text> : <WebsiteIcons domain={dataClient!.domain} websites={data[0].total_referrer.slice(0, 10).filter(x => x != "/")} />}
            </View>
        </ScrollView>
  
        <TouchableOpacity onPress={() => {
          AsyncStorage.removeItem("client_info")
          .then(() => {
            navigate("Login");
          });
        }} style={styles.logoutLink}>
          <Text style={styles.logoutLinkText} lightColor={Colors.light.tint}>Logg ut</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
    container:
    {
      flex: 1,
      alignItems: 'center'
    },

    title:
    {
      fontSize: 20,
      fontWeight: 'bold',
    },

    welcomeText: {
      textAlign: "center",
      marginTop: -150,
      fontSize: 18
    },

    textStat: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 28,
      marginTop: 25
    },

    header: {
      width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
      height: 225,
      position: "absolute",
      top: 0
    },

    separator:
    {
      marginVertical: 30,
      height: 1,
      width: '80%',
    },

    tinyLogo:
    {
      width: 64,
      height: 64
    },

    companyNameText: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 28
    },

    getStartedText:
    {
        fontSize: 17,
        lineHeight: 24,
        textAlign: 'center',
        fontWeight: 'bold',
        padding: 25
    },

    sloganContainer:
    {
      borderLeftColor: '#FCC64A',
      borderLeftWidth: 5,
      paddingLeft: 15
    },

    slogan:
    {
      fontSize: 42
    },

    helpLink: {
      paddingVertical: 15,
    },

    helpLinkText: {
      textAlign: 'center',
    },

    clientTitle: {
      fontWeight: "bold"
    },

    primaryColor: {
      width: 32,
      height: 32,
      margin: 10
    },

    secondaryColor: {
      width: 32,
      height: 32,
      margin: 10
    },
    
    banner: {
      width: (Dimensions.get("window").width > 900) ? 700 : Dimensions.get("window").width,
      height: 400
    },

    logoutLink: {
      paddingVertical: 15,
    },
  
    logoutLinkText: {
      textAlign: 'center',
    },      
});
